import styled from 'styles/styled';
import Input from 'components/ui/Input';
import copyIconImg from '../../../assets/Icon/Copyicon.svg';
import shareIconImg from '../../../assets/Icon/Share.png';
import iconClose from '../../../assets/Icon/icon_close.svg';
import imgCount from '../../../assets/countBG.svg';

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
  color: #EFEFEF;
  @media (max-width: 720px) {
    margin-bottom: 8px;
    font-size: 20px;
  }
`;

export const Btn = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #52D381;
  padding: 8px 14px;
  background-color: #292B2E;
  border-radius: 20px;
  border: 1px solid #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.8;
  }
`;

export const IconCopy = styled.span`
  background-image: url(${copyIconImg});
  width: 16px;
  height: 17px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 8px;
  &.shareIcon {
  background-image: url(${shareIconImg});
  }
`;

export const Row = styled.div`
  margin: 0 auto;
  display: table;
`;

export const BottomInfo = styled.div`
  margin-top: 35px;
  h3 {
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
  }
  p {
    word-break: break-word;
  }
`;

export const BoxQR = styled.div`
  width: 248px;
  height: 248px;
  margin: 32px 0 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrap = styled.div`
  max-width: 248px;
  button {
    width: 202px;
    min-height: 40px;
    text-align: center;
    margin: 0 auto;
  }
`;

export const Article = styled.div`
  padding: 40px 0;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 85px);
  min-height: calc(100svh - 85px);
  border-radius: 10px;
  background-color: #15191E;
  overflow: scroll;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    padding: 24px 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  h2 {
    text-align: center;
  }
  &.centerItem {
    align-items: center;
  }
`;

export const WrapModal = styled.div`
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Close = styled.div`
  background-image: url(${iconClose});
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0;
  top: -56px;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #454545;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.8;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  color: #EFEFEF;
`;

export const RowBelt = styled.div`
  margin-top: 12px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  a {
    min-width: calc(50% - 4px);
    margin: 0 auto;
  }
`;

export const SubTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 24px;
  color: #EFEFEF;
  @media (max-width: 720px) {
    font-size: 16px;
  }
  &.center {
    text-align: center;
    span {
      color: #52D381;
    }
  }
`;

export const Count = styled.h4`
  font-size: 55px;
  font-weight: 700;
  line-height: 120px;
  text-align: center;
  color: #000;
  max-width: 390px;
  width: 100%;
  background-image: url(${imgCount});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 120px;
  min-width: 390px;
  margin-top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadAnime = styled.div`
  display: block;
  width: 52px;
  height: 52px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  margin-bottom: 10px;
  box-shadow: 1px 1px 0 1px #52d382;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
