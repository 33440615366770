import styled from 'styles/styled';
import imgCalendar from '../../../assets/Icon/Calendar.svg';
import iconClose from '../../../assets/Icon/icon_close.svg';
import iconInfo from '../../../assets/icons/iconInfo.svg';

export const IconCalendar = styled.div`
  background-image: url(${imgCalendar});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 22px;
  height: 22px;
  margin-right: 10px;
`;

export const ErrorText = styled.p`
  color: #FF4B4A;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const IconInfo = styled.span`
  width: 12px;
  height: 12px;
  margin-top: 1px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 8px;
  display: inline-block;
  background-image: url(${iconInfo});
  &:hover {
    opacity: 0.6;
  }
`;

export const RowText = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
  color: #EFEFEF;
  @media (max-width: 720px) {
    margin-bottom: 16px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 720px) {
    width: 90%;
  }
  button.btnSubmit {
    margin-top: 48px;
    min-width: 200px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #EFEFEF;
  &.center {
    text-align: center;
  }
  &.inner {
    margin-bottom: 0;
  }
`;

export const TextBold = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #EFEFEF;
  span {
    color: #52D381;
  }
`;

export const Content = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 720px) {
    width: 90%;
  }
  label {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #EFEFEF;
    background-color: #292B2E;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    border-radius: 10px;
    border: 1px solid #292B2E;
    cursor: pointer;
  }
  input {
    display: none;
    &:checked + label {
      color: #52D381;
      border-color: #EFEFEF;
    }
  }
`;

export const Row = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;
  display: flex;
  max-width: 100%;
  width: 464px;
  justify-content: space-between;
  @media (max-width: 720px) {
    min-width: 264px;
    width: 100%;
  }
  &.RowLarge {
    flex-wrap: wrap;
    margin-bottom: 6px;
  }
`;

export const Col = styled.div`
  min-width: calc(50% - 4px);
  &.colSmall {
    min-width: calc(40% - 4px);
  }
  &.colLarge {
    min-width: calc(60% - 4px);
    label {
      color: #696969;
    }
    .react-datetime-picker__clear-button {
      display: none;
    }
    .react-datetime-picker {
      color: #fefefe;
      input {
        display: initial;
      }
    }
  }
`;

export const ColMaxW = styled.div`
  min-width: calc(33% - 5px);
  margin-bottom: 10px;
`;

export const CalendarWrap = styled.div`
  button.center {
    margin: 0 auto;
    min-width: 168px;
  }
  p {
    text-align: center;
    margin-bottom: 19px;
  }
  .react-calendar__navigation button:disabled {
    background-color: transparent;
    color: #696969;
  }
  .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: transparent;
  }
  .react-calendar__navigation__label {
    pointer-events: none;
  }
  .react-calendar {
    margin-bottom: 32px;
    padding: 12px 8px 8px 8px;
    border: none;
    background-color: #292B2E;
    border-radius: 14px;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 1.5px;
    .react-calendar__navigation {
      height: inherit;
      margin-bottom: 7px;
    }
    .react-calendar__month-view__weekdays {
      margin-bottom: 22px;
    }
    .react-calendar__month-view__weekdays {
      abbr {
        text-decoration: none;
        color: #B5BEC6;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 1.5px;
      }
    }
    button {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      height: 30px;
      width: 30px;
      padding: 0;
      margin-bottom: 8px;
    }
    .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
      background-color: transparent;
      abbr {
        width: 30px;
        height: 30px;
        display: flex;
        border-radius: 100px;
        border: 1px solid #52D381;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: #52D381;
      }
    }
    .react-calendar__tile--now {
      background-color: transparent;
      color: #52D381;
    }
    .react-calendar__tile--active {
      background-color: transparent;
      abbr {
        width: 30px;
        height: 30px;
        display: flex;
        border-radius: 100px;
        background-color: #52D381;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: #000000 !important;
      }
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
    .react-calendar__tile:disabled {
      background-color: #292B2E;
      color: #696969;
    }
  }
`;

export const Close = styled.div`
  background-image: url(${iconClose});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -56px;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: #454545;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms linear;
  &:hover {
    opacity: 0.8;
  }
`;
