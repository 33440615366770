import React, { useEffect, useState } from 'react';
import { TournamentCountdown } from '../TournamentCountdown/TournamentCountdown';
import { useSocket } from 'utils/hooks/useSocket';
import { formatCurrency } from 'utils';
import Cookies from 'js-cookie';
import { useTournament } from 'context/TournamentContext';
import LogoDetails from '../../../assets/resultsPhoto.png';
import LogoBanner from '../../../assets/Banner/BLK_LOGO_WORDMARK_WALLET_NEG.png';

import {
  Title,
  SubTitle,
  Row,
  ContentPhoto,
  ContentResults,
  TournamentRank,
  LineText,
  TournamentList,
  NickName,
  Percent,
  Amount,
  TournamentBlet,
  WrappList,
  BeltBottom,
  Banner,
} from './styled';
import { getTournamentRanking } from 'api/tournamentService';

type TournamentRankingData = [string, string][]; // Updated to reflect the new data structure

type TournamentDetails = {
  data?: {
    id?: number;
    attributes?: {
      name?: string;
      end_at?: string;
      amount_start?: number;
      market_type?: any;
    };
  };
};

const TournamentRanking = () => {
  const { tournamentRanking, tournamentDetails } = useSocket() as { tournamentRanking: TournamentRankingData, tournamentDetails: TournamentDetails };
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const { tournamentStatus } = useTournament();
  const [priceReward, setPriceReward] = useState(null);
  const [initialMoney, setInitialMoney] = useState(0);
  const [localTournamentRanking, setLocalTournamentRanking] = useState<TournamentRankingData | null>(null);

  useEffect(() => {
    const checkTournamentRanking = async () => {
      const tournamentId = tournamentDetails?.data?.id;
      const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;

      setInitialMoney(initialMoney);
      ///////////////////////////////////////////////////////
      if (tournamentId === 82 || tournamentId === 85) {
        setPriceReward('Name')
      }

      if (tournamentId === 87) {
        setPriceReward('Name_87')
      }

      if (tournamentId === 85) {
        setPriceReward('Name_more')
      }

      if (tournamentId === 120) {
        setPriceReward('Name_500')
      }

      if (tournamentId === 125) {
        setPriceReward('ranking_125')
      }

      if (tournamentId === 122) {
        setPriceReward('ranking_BL')
      }
      ///////////////////////////////////////////////////////
      if (tournamentId) {
        try {
          const apiData = await getTournamentRanking(tournamentId);
          setLocalTournamentRanking(apiData);
        } catch (error) {
          console.error('Error fetching tournament ranking:', error);
        }
      } else {
        setLocalTournamentRanking(tournamentRanking);
      }
    };

    checkTournamentRanking();

  }, [tournamentRanking, tournamentDetails?.data?.id, tournamentDetails?.data?.attributes?.amount_start]);


  return (
    <>
      <Title>{tournamentDetails?.data?.attributes?.name}</Title>

      <Row>
        <ContentPhoto>
          <img src={LogoDetails} alt="Img Logo Details" />
        </ContentPhoto>
        <ContentResults>
          <SubTitle>Current Results</SubTitle>
          <TournamentRank>

            {(Array.isArray(localTournamentRanking) ? localTournamentRanking : []).map(([player, balanceString]) => {
              const totalUSDT = parseFloat(balanceString);
              const percentageChange = ((totalUSDT - initialMoney) / initialMoney) * 100;
              const isInvalidData = isNaN(totalUSDT) || isNaN(percentageChange);
              return (
                <TournamentList key={player}>
                  <WrappList className={player === username ? 'is_me' : ''}>
                    <TournamentBlet className={player === username ? 'is_me' : ''}>
                      <NickName className={priceReward}>{player}</NickName>
                      {!isInvalidData && (
                        <Percent>
                          {percentageChange > 0 ? `+${percentageChange.toFixed(2)}` : percentageChange.toFixed(2)}%
                        </Percent>
                      )}
                    </TournamentBlet>
                    {!isInvalidData && (
                      <Amount className={`${totalUSDT > initialMoney ? 'up_state' : totalUSDT < initialMoney ? 'down_state' : ''}`}>
                        {formatCurrency(totalUSDT)} USDT
                      </Amount>
                    )}
                  </WrappList>
                </TournamentList>
              );
            })}
          </TournamentRank>
        </ContentResults>
      </Row>

      <BeltBottom>
        <p>Time left:</p>

        {(tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
          <>
            {(tournamentStatus === 'BEFORE_START') ? (
              <SubTitle className="inner">Coming Soon</SubTitle>
            ) : <TournamentCountdown date={tournamentDetails?.data?.attributes?.end_at} /> }
          </>
        ) : <LineText>Finished</LineText> }

      </BeltBottom>
      <Banner className={priceReward}>Sponsored by: <img src={LogoBanner} alt="Logo Banner" /></Banner>
    </>
  );
};

export default TournamentRanking;
