import { useEffect, useState, useContext, createContext } from 'react';
import { initializeSocket, emitRefreshTournamentRanking, setRefreshTournamentRankingListener, setTradingUpdateListener, disconnectSocket, getPriceBySelectedCoin, getAllCoinsPrices } from "api/socketService";
import { useTournament } from 'context/TournamentContext';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getTournamentRanking } from 'api/tournamentService';


const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

const getShortName = (coin) =>{
  if (typeof coin === 'string' || coin instanceof String){
    return coin.split('/')[0].toLowerCase()
  }
  if(coin?.name){
    return coin?.name.split('/')[0].toLowerCase()
  }
  if(coin?.attributes){
    return coin?.attributes?.base?.data?.attributes?.short_name.toLowerCase()
  }else{
    return coin?.base?.data?.attributes?.short_name.toLowerCase()
  }
}

export const SocketProvider = ({ children }) => {
  const { id } = useParams();

  const username = localStorage.getItem('username') ?? Cookies.get('username');

  const [allCoins, setAllCoins] = useState();
  const [allCoinsValues, setAllCoinsValues] = useState();

  const [selectedCoin, selectCoin] = useState();
  const [selectedCoinValue, setSelectedCoinValue] = useState();

  const [selectedOHLCVValue, setOHLCVValue] = useState();

  const setCoinsPricesObj = (newData) => {
    setAllCoinsValues(prevState => ({
      ...prevState,
      [newData.key]: newData.data
    }));
  }

  const setCoin = (data) => {
    if (typeof data === 'string' || data instanceof String) {
      selectCoin({'name': `${data}`})
    } else {
      selectCoin(data)
    }
  }

  useEffect(() => {
    if (allCoinsValues && allCoinsValues[getShortName(selectedCoin)]) {
      setSelectedCoinValue(allCoinsValues[getShortName(selectedCoin)])
    } else {
      setSelectedCoinValue(0)
    }

    if (allCoinsValues && allCoinsValues[`${getShortName(selectedCoin)}OHLCV`]) {
      setOHLCVValue(allCoinsValues[`${getShortName(selectedCoin)}OHLCV`])
    }

    if (selectedCoin !== undefined) {
      sessionStorage.setItem('selectedCoin', JSON.stringify(selectedCoin));
    }
  }, [selectedCoin])

  useEffect(() => {
    if (allCoinsValues && allCoinsValues[getShortName(selectedCoin)]) {
      setSelectedCoinValue(allCoinsValues[getShortName(selectedCoin)])
    }
    if (allCoinsValues && allCoinsValues[`${getShortName(selectedCoin)}OHLCV`]) {
      setOHLCVValue(allCoinsValues[`${getShortName(selectedCoin)}OHLCV`])
    }
  }, [allCoinsValues])

  useEffect(() => {
    if (allCoins?.length) {
      getAllCoinsPrices(allCoins, setCoinsPricesObj);

      if (sessionStorage.hasOwnProperty("selectedCoin")) {
        const savedVariable = sessionStorage.getItem('selectedCoin');
        setCoin(JSON.parse(savedVariable));
      } else {
        if (selectedCoin === undefined) {
          const btcPair = allCoins.find(coin => coin.attributes.name === 'BTC/USDT');
          if (btcPair) {
            setCoin(btcPair.attributes);
          } else {
            setCoin(allCoins[0].attributes);
          }
        }
      }
    }
  }, [allCoins]);

  const [socket, setSocket] = useState(null);
  const [tournamentRanking, setTournamentRanking] = useState({});
  const [socketConnected, setSocketConnected] = useState(false);
  const { tournamentDetails } = useTournament();
  const [rank, setRank] = useState(null);

  const [tradingUpdates, setTradingUpdates] = useState([]);

  useEffect(() => {
    const initSocket = async () => {
      const token = localStorage.getItem('jwt') ?? Cookies.get('jwt');
      const tournamentId = tournamentDetails?.data?.id || id;
      const tradeType = tournamentDetails?.data?.attributes?.trade_type;

      const initializedSocket = await initializeSocket(token, tournamentId, () => setSocketConnected(true));

      setSocket(initializedSocket);
      emitRefreshTournamentRanking(tradeType);
      setRefreshTournamentRankingListener(tradeType, tournamentId, handleRefreshTournamentRanking);

      if(!Object.keys(tournamentRanking).length > 0){ 
        setTournamentRanking(await getTournamentRanking(tournamentId));
      }

      setTradingUpdateListener(tradeType, handleTradingUpdate);
    };

    initSocket();

    return () => {
      const tradeType = tournamentDetails?.data?.attributes?.trade_type;
      disconnectSocket(tradeType);
    };
  }, [tournamentDetails]);

  useEffect(() => {
    getRank();
  }, [tournamentRanking])

  const getRank = () => {
    if (!Array.isArray(tournamentRanking)) {
      setRank(null);
      return;
    }

    const rankIndex = tournamentRanking.findIndex(([user]) => user === username);
    const rank = rankIndex !== -1 ? rankIndex + 1 : null;

    setRank(rank);
  };

  // Define the handler for refreshTournamentRanking
  const handleRefreshTournamentRanking = (data) => {
    if (data && Object.keys(data).length > 0) {
      setTournamentRanking(data);
    }
  };

  // Define the handler for trading updates
  const handleTradingUpdate = (data) => {
    if (data && Object.keys(data).length > 0) {
      setTradingUpdates(data);
    }
  };

  const value = {
    socket,
    tournamentRanking,
    rank,
    tournamentDetails,
    getPriceBySelectedCoin,
    getAllCoinsPrices,
    setAllCoins,
    allCoinsValues,
    setCoin,
    selectedCoin,
    selectedCoinValue,
    selectedOHLCVValue,
    tradingUpdates
  };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketContext;
