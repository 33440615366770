import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import FullPageLoad from 'components/ui/FullPageLoad';
import BaseWrapper from 'components/layout/BaseWrapper';
import { ButtonFilled } from 'components/ui/Button';
import TournamentLoad from 'components/CreatingTournament/TournamentLoad';
import { getTournamentDetails, joinTournament, readyTournament } from 'api/tournamentService';
import { socket, initializeSocket } from 'api/socketService';
import * as routes from 'constants/routes';
import Cookies from 'js-cookie';
import { useUser } from 'api/userService';
import { registerAnonymousUser } from 'utils/auth/anonymousRegistration';

import {
  Wrapper,
  SubTitle,
  LoadAnime,
  SubTitleLarge,
  Row,
  ErrorInfo,
  Count,
} from './styled';

interface TournamentData {
  id: number;
  attributes: {
    status: string;
    start_at: string;
    end_at: string;
    is_public: boolean;
    name: string;
    hash: string;
    users_limit: number;
    creator: {
      username: string;
    };
    allow_anonymous_users: boolean;
  };
}

const TournamentsUser = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const hash = searchParams.get('hash');
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const [isLoadPage, setLoadPage] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasJoined, setHasJoined] = useState(false);
  const [tournamentData, setTournamentData] = useState<TournamentData | null>(null);
  const [isWaitingForOpponent, setIsWaitingForOpponent] = useState(false);
  const [isOpenedStartLoad, setIsOpenedStartLoad] = useState(false);
  const [seconds, setSeconds] = useState(11);

  useEffect(() => {
    const loadTournamentAndJoin = async () => {
      setLoadPage(true);
      setError(null);

      try {
        if (!id) {
          throw new Error('Tournament ID is required');
        }
        if (!hash) {
          throw new Error('Tournament hash is required');
        }

        // Step 1: Get tournament details
        const response = await getTournamentDetails(id);
        setTournamentData(response.data);

        // Step 2: If not logged in and tournament allows anonymous users, register anonymous user
        if (!isLoggedIn && response.data.attributes.allow_anonymous_users) {
          const credentials = await registerAnonymousUser();
          localStorage.setItem('username', credentials.username);
          localStorage.setItem('email', credentials.email);
        }

        // Step 3: Join tournament and ensure it's completed
        console.log('Joining tournament...');
        const joinResponse = await joinTournament(id, hash);
        console.log('Join response:', joinResponse);

        if (joinResponse !== 'User successfully added to the tournament!') {
          throw new Error('Failed to join tournament');
        }

        console.log('Successfully joined tournament');
        setHasJoined(true);
        setLoadPage(false);

        // Step 4: Wait for backend to process the join
        console.log('Waiting for backend to process join...');
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Step 5: Set up socket with forced reconnect
        const token = Cookies.get('jwt') || localStorage.getItem('jwt');
        console.log('Token:', token);
        if (token) {
          console.log('Setting up fresh socket connection...');

          const handleConnect = () => {
            console.log('Socket connected callback triggered');
            if (socket) {
              console.log('Setting up tournamentStarted listener');
              socket.on('tournamentStarted', (data) => {
                console.log('Tournament started event received with data:', data);
                setIsOpenedStartLoad(true);
                setSeconds(11);
                setTimeout(() => {
                  window.onload = function () {window.location.reload()}
                  navigate(routes.GAME.replace(':id', id.toString()));
                  window.location.reload();
                }, 10000);
              });
            } else {
              console.error('Socket not available in handleConnect');
            }
          };

          try {
            console.log('Initializing socket connection with force reconnect...');
            const connectedSocket = await initializeSocket(token, id.toString(), handleConnect, true);
            console.log('Socket connection initialized:', connectedSocket?.connected);
          } catch (socketError) {
            console.error('Failed to initialize socket:', socketError);
            throw socketError;
          }
        }
      } catch (err) {
        console.error('Error in tournament setup:', err);
        setError(err.message || 'Failed to load tournament details');
        setLoadPage(false);
      }
    };

    loadTournamentAndJoin();

    return () => {
      if (socket) {
        console.log('Cleaning up socket event listeners');
        socket.off('tournamentStarted');
        socket.disconnect();
      }
    };
  }, [id, navigate]);

  const handleStart = async () => {
    try {
      if (!tournamentData?.id) return;

      setIsWaitingForOpponent(true);
      await readyTournament(tournamentData.id.toString());
    } catch (err) {
      console.error('Error in handleStart:', err);
      setIsWaitingForOpponent(false);
    }
  };

  useEffect(() => {
    const secondInterval = setInterval(() => {
      setSeconds(prev => (prev - 1 < 0 ? 59 : prev - 1));
    }, 1000);
    return () => {
      clearInterval(secondInterval);
    };
  }, []);

  if (isLoadPage) {
    return <FullPageLoad/>;
  }

  if (error) {
    return (
      <BaseWrapper>
        <Wrapper>
          <TournamentLoad>
            <Row>
              <ErrorInfo>{error}</ErrorInfo>
              <Link to={'https://tradingbattles.com/#upcoming-tournaments'}>
                <ButtonFilled>Home</ButtonFilled>
              </Link>
            </Row>
          </TournamentLoad>
        </Wrapper>
      </BaseWrapper>
    );
  }

  return (
    <BaseWrapper>
      <Wrapper>
        <TournamentLoad>
          {!isOpenedStartLoad && (
            <Row>
              {isWaitingForOpponent ? (
                <SubTitleLarge>
                  Waiting for opponent...
                </SubTitleLarge>
              ) : (
                <>
                  <SubTitleLarge>
                    You are about to join {tournamentData?.attributes.name} {localStorage.getItem('username')} battle!
                  </SubTitleLarge>
                  {hasJoined && (
                    <ButtonFilled onClick={handleStart}>Start</ButtonFilled>
                  )}
                </>
              )}
            </Row>
          )}

          {isOpenedStartLoad && (
            <>
              <SubTitleLarge>The battle starts in</SubTitleLarge>

              {(seconds < 11 || seconds === 0) ? (
                <Count>
                  {seconds}
                </Count>
              ) :
                <LoadAnime/>
              }
            </>
          )}

        </TournamentLoad>
      </Wrapper>
    </BaseWrapper>
  );
};

export default TournamentsUser;
